@font-face {
  src: url(./lib/font/THICCCBOI-SemiBold.ttf);
  font-family: 'SemiBold';
  font-weight: normal;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
}

* {
  font-family: 'SemiBold';
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
}

body {
  overscroll-behavior: contain;
  scroll-behavior: smooth;
}

a:visited,
a {
  color: black;
  text-decoration: none;
}

li {
  list-style-type: none;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 90%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.gap-1rem {
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row !important;
}

.space-between {
  justify-content: space-between;
}

.margin {
  margin-left: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}

p {
  font-weight: 500;
}

img {
  width: 100%;
  object-fit: cover;
  transform: perspective(1000px);
  max-width: 100%;
  display: inline-block;
}
