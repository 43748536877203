.map-container {
  height: 400px;
  width: 80%;
  border-radius: 12px;
  transition: none;
  margin-bottom: 50px;
}

@media (max-width: 1920px) {
  .map-container {
    height: 600px;
  }
}

@media (max-width: 1440px) {
  .map-container {
    height: 400px;
  }
}

@media (max-width: 1024px) {
  .map-container {
    height: 300px;
  }
}
